class BlogPaginator {

	constructor(eventEmitter, id, domNode, props = undefined) {

		BlogPaginator.init();
	}

	init2() {

		BlogPaginator.init();
	}

	static init() {

		if (document.querySelector('#blog-view-more')) {
			document.querySelector('#blog-view-more').addEventListener('click', (e) => {
				e.preventDefault();
				e.stopPropagation();
				BlogPaginator.loadMore();
			})	
		}
	}

	static loadMore() {

		document.querySelector('#loading-blog').style.display = 'block';
		let paged = document.querySelector('#blog-paged').value;
		let xmlhttp = BlogPaginator.ajaxReq()
		let url = encodeURI('/blog?paged=' + ( parseInt( paged ) + 1 ) ) // eslint-disable-line

		xmlhttp.open( 'GET', url, true ) // set true for async, false for sync request
		xmlhttp.onreadystatechange = function () {
			if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {				
				try {
					const parser = new DOMParser();
					let data = parser.parseFromString( xmlhttp.response, 'text/html' );
					
					// blog-content
					document.querySelector('#blog-content').innerHTML = data.querySelector('#blog-content').innerHTML;
					BlogPaginator.init();
				} catch (error) {
					// Show notification
					console.log(xmlhttp.statusText);
				}
			}
		}
		xmlhttp.send();
	}

	static ajaxReq() {
		
		if (window.XMLHttpRequest) {
			return new XMLHttpRequest() // eslint-disable-line
		} else if (window.ActiveXObject) {
			return new ActiveXObject('Microsoft.XMLHTTP') // eslint-disable-line
		} else {
			console.log('Browser does not support XMLHTTP.')
			return false
		}
	}

}

export default BlogPaginator
