import { ScrollTrigger } from "gsap/ScrollTrigger";
class CardsGridZoom  {

    constructor( module ) {
  
		this._DOM_MAP= {

			RANGE_INPUT: module.querySelector("[type='range']"),
			RANGE_MINUS_BUTTON:  module.querySelector(".minus"),
			RANGE_PLUS_BUTTON:  module.querySelector(".plus"),
			GRID: module.querySelector(".grid"),
			CARD_LIST: Array.from( module.querySelectorAll(".gbcn-card") )
		};
		if( this._DOM_MAP.RANGE_INPUT ) this._init();
    }

	destroy() {

		if( this._DOM_MAP.RANGE_INPUT ) {
			this._destroyViewportMonitoring();
			this._destroyListeners();
		}
	}

	_destroyListeners() {

		this._DOM_MAP.RANGE_INPUT.removeEventListener( "input", this._rangeInputHandler );
		this._DOM_MAP.RANGE_MINUS_BUTTON.removeEventListener( "click", this._minusPlusRangeHandler );
		this._DOM_MAP.RANGE_PLUS_BUTTON.removeEventListener( "click", this._minusPlusRangeHandler );
        window.removeEventListener("resize", this._resizeViewPortHandler );
    }

	_destroyViewportMonitoring() {

        this._MEDIA_QUERIES.forEach( mediaQuery => {
            mediaQuery.removeEventListener('change', this._mediaqueriesHandler);
            mediaQuery = null;
        })
        this._MEDIA_QUERIES = null;
    }

    _init() {

        this._PRODUCT_MIN_WIDTH = 140;
        this._MEDIA_QUERIES = [ 
								window.matchMedia( '(min-width: 360px) and (max-width: 767px)' ) , 
                                window.matchMedia( '(min-width: 768px)' )  
        ];
        this._currentRange = this._gridContainerWidth = this._gap = null;
        this._viewPortGreaterThan1440 = false;
		this._viewPortLessThan768 = false;
            
        this._initListeners();
        this._initViewportMonitoring();
        this._updateInputRangePositionAccordingToTheCurrentNumberOfColumns();
    }

    _initListeners() {

        this._rangeInputHandler=this._rangeInputHandler.bind(this);
        this._DOM_MAP.RANGE_INPUT.addEventListener( "input", this._rangeInputHandler );

		this._minusPlusRangeHandler=this._minusPlusRangeHandler.bind(this);
		this._DOM_MAP.RANGE_MINUS_BUTTON.addEventListener( "click", this._minusPlusRangeHandler );
		this._DOM_MAP.RANGE_PLUS_BUTTON.addEventListener( "click", this._minusPlusRangeHandler );

        this._resizeViewPortHandler = this._throttle( this._resizeViewPortHandler.bind( this ), 200 );
        window.addEventListener("resize", this._resizeViewPortHandler );
    }

    _rangeInputHandler(e) {

        this._currentRange = this._DOM_MAP.RANGE_INPUT.value;
        this._redrawGrid();
     }

	 _minusPlusRangeHandler(e) {

		const valueToAdd = e.currentTarget.classList.contains("minus")? -1 : 1;
		const endOfRange = e.currentTarget.classList.contains("minus")? 1 : 6;
		
		if( this._DOM_MAP.RANGE_INPUT.value != endOfRange ) {
			this._DOM_MAP.RANGE_INPUT.value = this._currentRange = Number( this._DOM_MAP.RANGE_INPUT.value ) + valueToAdd ;
		}

		this._redrawGrid();
	 }

     _resizeViewPortHandler(e= null) {

        this._updateInputRangePositionAccordingToTheCurrentNumberOfColumns();
    }

     _updateInputRangePositionAccordingToTheCurrentNumberOfColumns() {

        if( this._viewPortLessThan768 ) return;

        const products= this._DOM_MAP.CARD_LIST;
        const currentWidthOfProduct = products[0].getBoundingClientRect().width;

        const gridContainerComputedStyle = window.getComputedStyle( this._DOM_MAP.GRID );
        this._gap = parseInt( gridContainerComputedStyle.getPropertyValue( "column-gap" ) );
        this._gridContainerWidth= this._DOM_MAP.GRID.offsetWidth;
        const numberOfproductsForRow = Math.floor( ( this._gridContainerWidth + this._gap ) / ( currentWidthOfProduct + this._gap ) );
        this._DOM_MAP.RANGE_INPUT.value = this._currentRange = numberOfproductsForRow;
     }

     _initalizeRange() {

        if( this._viewPortLessThan768 ) return;

        const inputRange = this._DOM_MAP.RANGE_INPUT;
        inputRange.min = 1;
        inputRange.step = 1;

        let steps = 6;

        inputRange.max = steps; 
        inputRange.value = this._currentRange = Math.ceil( steps/2 );

        if( !this._viewPortGreaterThan1440 ) {
    
            const gridContainerComputedStyle = window.getComputedStyle( this._DOM_MAP.GRID );
            this._gap = parseInt( gridContainerComputedStyle.getPropertyValue( "column-gap" ) );
            this._gridContainerWidth= this._DOM_MAP.GRID.offsetWidth;
            steps= Math.floor( ( this._gridContainerWidth + this._gap ) / ( this._PRODUCT_MIN_WIDTH + this._gap ) )

            inputRange.max = steps; 
            inputRange.value = this._currentRange = Math.round( steps/2 );
        }
    }

     _redrawGrid() {

		if( this._viewPortLessThan768 ) {
    
			this._DOM_MAP.GRID.style.gridTemplateColumns= null;
        }
        else {
            this._DOM_MAP.GRID.style.gridTemplateColumns= `repeat(${this._currentRange  }, 1fr)`;
        }

        ScrollTrigger.refresh();
     }

     _initViewportMonitoring() {

        this._mediaqueriesHandler = this._mediaqueriesHandler.bind(this);

        this._MEDIA_QUERIES.forEach( mediaQuery => {
            mediaQuery.addEventListener('change', this._mediaqueriesHandler);
        })

        this._updateUItoCurrentBreakpoint();
    }

    _mediaqueriesHandler( e = null ) {

        this._updateUItoCurrentBreakpoint();
    }

    _updateUItoCurrentBreakpoint() {

        this._MEDIA_QUERIES.forEach( mediaquery => {

                if( mediaquery.matches ) {
                    this._viewPortGreaterThan1440 = mediaquery.media === "(min-width: 768px)";
					this._viewPortLessThan768 = mediaquery.media === "(min-width: 360px) and (max-width: 767px)";
                    this._initalizeRange();
                    this._redrawGrid();
                }
            }
        )
    }

    _throttle (callback, wait = 100) {

        let timer = null
    
        return function (args) {

          if (timer === null) {
              
            timer = setTimeout(() => {
               callback.apply(this, args)
              timer = null
            }, wait)
          }
        }
      }
}
  
export default CardsGridZoom ;