const TEMPLATE= `
	<section id="custom-mouse">
		<div class="custom-mouse__default">
			<svg class="custom-mouse__default-svg" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 231.7 100" style="enable-background:new 0 0 231.7 100;" xml:space="preserve">
				<circle cx="115.8" cy="50" r="12"></circle>
			</svg>
		</div>
	</section>
`
const OFFSET_CENTERED_MOUSE= 4;

class CustomMouse {

	constructor( module ) {

		if (matchMedia('(any-pointer:fine)').matches) {

			document.body.classList.add( "hidden-mouse" );
			if( !module.querySelector("#custom-mouse") ) module.insertAdjacentHTML( "beforeend", TEMPLATE );

			this._xmouse=0;
			this._ymouse=0;

			this._DOM_MAP= {

				CONTAINER: module,
				MOUSE_CONTAINER: module.querySelector(".custom-mouse"),
				MOUSE: module.querySelector(".custom-mouse__default"),
			}

            this._init();
        }
	}

	destroy() {

		window.removeEventListener("mousemove", this._mouseProcess);
        window.removeEventListener("scroll", this._mouseProcess);
	}

	_init() {

		this._halfWidthDefaultMouse = this._DOM_MAP.MOUSE.getBoundingClientRect().width / 2;
		this._widthDefaultMouse = this._DOM_MAP.MOUSE.getBoundingClientRect().width
        this._halfHeightDefaultMouse = this._DOM_MAP.MOUSE.getBoundingClientRect().height / 2;

		this._mouseProcess = this._mouseProcess.bind( this );
		window.addEventListener("mousemove", this._mouseProcess);
        window.addEventListener("scroll", this._mouseProcess);
	}

	_mouseProcess(e) {

        let scrollTop =  (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

        this._ymouse= e.clientY || this._ymouse
        this._xmouse= e.clientX;  

		const mouseX= this._xmouse - OFFSET_CENTERED_MOUSE - this._widthDefaultMouse;
		const mouseY= scrollTop + this._ymouse - this._halfHeightDefaultMouse;
        // this._DOM_MAP.MOUSE.style.transform=`translate( ${mouseX}px, ${mouseY}px )`;
		this._DOM_MAP.MOUSE.style.left = mouseX+"px";
		this._DOM_MAP.MOUSE.style.top = mouseY+"px";
    }
}

export default CustomMouse;