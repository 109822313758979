/* eslint-disable class-methods-use-this */

// Import libraries
import LazyLoading from "./lib/lazy-loading";
// import LottieLoader from "./lib/lottie";
import CoverZoomButton from "./lib/cover-zoom-button";

import BlogPaginator from "./lib/blog-paginator";

// Import components
import Cta from "./template-parts/components/cta";
import Pagination from "./template-parts/components/pagination";

// Import sections
import Facts from "./template-parts/sections/facts";
import Locations from "./template-parts/sections/locations";
import Team from "./template-parts/sections/team";
import Timeline from "./template-parts/sections/timeline";
import Forms from "../../template-parts/sections/forms/forms";

export default class ModulesLoader {
	constructor(el = document) {
		this.el = el;
	}

	loadModules() {
		// Load libraries
		window.lazyLoading = new LazyLoading();
		// window.lottieLoader = new LottieLoader();
		window.coverzoombutton = new CoverZoomButton();

		// Load components
		window.cta = new Cta();
		window.pagination = new Pagination();

		// Load sections
		window.facts = new Facts();
		window.locations = new Locations();
		window.team = new Team();
		window.timeline = new Timeline();
		window.forms = new Forms();
		window.blogpaginator = new BlogPaginator();

	}
}
