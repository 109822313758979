import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

class BackgroundParallax {

	constructor( module ) {

		gsap.registerPlugin( ScrollTrigger );

		this._timeLine= [];
		this._DOM_MAP= {

            TRIGGER: document.querySelector("html"),
            BACKGROUND: module,
        };

		this._init();
	}

	destroy() {

		if( this._timeLine.length>0 ) {
			this._timeLine[0].kill();
			this._timeLine= null;

		}
	}

	_init() {

        this._timeLine.push( gsap.timeline({

					scrollTrigger: {
						trigger: this._DOM_MAP.TRIGGER,
						start: "top top",
						end: "bottom top",
						scrub: true
					}
				}
			)
			// .to( this._DOM_MAP.BACKGROUND, {backgroundPositionY: "-250%", ease:"none"}, 0)
			.to( this._DOM_MAP.BACKGROUND, { backgroundPositionY: "-1000px", ease:"none" }, 0)
		);    
	}
}

export default BackgroundParallax;