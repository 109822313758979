class Team {
	constructor() {
		this.teamMembers = document.getElementsByClassName("js-team");
		this.header = document.getElementById("header");
		if (this.teamMembers[0]) {
			this.init();
		}
	}

	init() {
	
		const listItems = document.querySelectorAll(".team__members li");
		const self = this;

		if (listItems) {
			Array.prototype.forEach.call(listItems, function (listItem) {
		
				const listItemImage = listItem.querySelector("img");
				const listItemPopupCloseButton =
					listItem.getElementsByClassName("team__close-popup")[0];

				if ( listItemImage && listItem.querySelector(".team__members__description") ) {
					listItemImage.addEventListener("click", function (event) {
						event.preventDefault();
						self.openPopup(listItem);
					});
				}
				else {
						listItem.style.pointerEvents = "none";
				}

				if (listItemPopupCloseButton) {
					const listPopup = listItem.getElementsByClassName("team__popup")[0];

					listItemPopupCloseButton.addEventListener("click", function (event) {
						event.preventDefault();

						// Enable scroll
						window.disableScroll.enableScroll(); // Unlock scroll

						// Close popup
						document.querySelector('.pelicula').classList.remove('team__popup-visible');
						listPopup.style.display = "none";
					});
				}
			});
		}
	}

	openPopup(listItem) {
		const listPopup = listItem.getElementsByClassName("team__popup")[0];

		// Disable scroll
		window.disableScroll.disableScroll(); // Block scroll

		// Hide header
		this.header.classList.remove("fixed");

		// Open popup
		document.querySelector('.pelicula').classList.add('team__popup-visible');
		listPopup.style.display = "initial";
	}
}

export default Team;
