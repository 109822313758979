class CoverZoomButton {
	constructor() {
		if ( document.querySelector('#show-book') ) {
			document.querySelector('#show-book').addEventListener('click', () => {
				document.querySelector('#single-product-popup').style.display = 'block'
				document.querySelector('html').style.overflowY = 'hidden'
			})
		}
		if ( document.querySelector('.single-product-popup__close') ) {
			document.querySelector('.single-product-popup__close').addEventListener('click', () => {
				document.querySelector('#single-product-popup').style.display = 'none'
				document.querySelector('html').style.overflowY = 'scroll'
			})
		}	
	}
}

export default CoverZoomButton;
