import "./scss/style.scss";
// import ScrollMagic from "scrollmagic";
// import Header from "./js/template-parts/common/header";
import ModulesLoader from "./js/modules-loader";
import Barba from "./js/lib/barba";
import Cookies from "./js/template-parts/popups/cookies";
import DisableScroll from "./js/lib/disable-scroll";
import BehaviourLauncher from "./js/template-parts/common/behaviourLauncher";
import { viewportHeightController } from "./js/template-parts/utils/jumble";
import MainMenu from "./js/template-parts/common/mainMenu";

// Load objects
document.addEventListener("DOMContentLoaded", () => {

	// BarbaJS
	window.barba = new Barba();
});

window.addEventListener("load", () => {

	viewportHeightController();

	window.mainMenu = new MainMenu();
	window.behaviourLauncher = new BehaviourLauncher();

	// Ajax requests
	window.ajaxReq = function ajaxReq() {
		if (window.XMLHttpRequest) {
			return new XMLHttpRequest(); // eslint-disable-line
		}

		if (window.ActiveXObject) {
			return new ActiveXObject("Microsoft.XMLHTTP"); // eslint-disable-line no-console
		}

		console.log("Browser does not support XMLHTTP."); // eslint-disable-line no-console
		return false;
	};

	// Set animations
	// window.setAnimationScenes = function setAnimationScenes(
	// 	scrollMagicController
	// ) {
	// 	const animatableElements = [
	// 		".cards-slider",
	// 		".facts",
	// 		".featured-content",
	// 		".featured-links",
	// 		".grid-card",
	// 		".hero",
	// 		".locations",
	// 		".numbers",
	// 		".team",
	// 		".timeline",
	// 		".features",
	// 		".list__list",
	// 		".featured-text",
	// 		".text",
	// 		".floating-images",
	// 		".brands",
	// 	];
	// 	// Set animation classes to all the elements that will use them.
	// 	/* eslint-disable no-restricted-syntax */
	// 	for (const el of Array.from(
	// 		document.querySelectorAll(animatableElements.join(","))
	// 	)) {
	// 		new ScrollMagic.Scene({
	// 			triggerElement: el,
	// 			triggerHook: 0.75,
	// 			reverse: false,
	// 		})
	// 			.setClassToggle(el, "animated")
	// 			.addTo(scrollMagicController);
	// 	}
	// 	/* eslint-enable no-restricted-syntax */
	// };

	// Load JS scripts
	window.loadJS = function loadJS(file, callback) {
		// DOM: Create the script element
		const jsElm = document.createElement("script");
		// set the type attribute
		jsElm.type = "application/javascript";
		// make the script element load file
		jsElm.src = file;
		// add a callback
		jsElm.addEventListener("load", callback);
		// finally insert the element to the body element in order to load the script
		document.body.appendChild(jsElm);
	};

	// Disable scroll
	window.disableScroll = new DisableScroll();

	// Modules loader
	window.modulesLoader = new ModulesLoader();
	window.modulesLoader.loadModules();

	// Cookies banner
	window.cookies = new Cookies();

	// Animations
	// window.setAnimationScenes(new ScrollMagic.Controller());
});
