import BackgroundParallax from "../behaviours/BackgroundParallax";
import CardsGridZoom from "../behaviours/CardsGridZoom";
import CustomMouse from "../behaviours/CustomMouse";

const behaviourList = [
	{ module : ".gbcn-cards-grid", name: CardsGridZoom },
	{ module : "body", name: BackgroundParallax },
	{ module : "body", name: CustomMouse },
]

class BehaviourLauncher {

	constructor() {

		this._looksForBehaviorsAndInitializesThem();
	}

	_looksForBehaviorsAndInitializesThem() {

		this._instantiatedBehaviors = [];

		behaviourList.forEach( behaviour => 
			{
				const foundModules = Array.from( document.querySelectorAll( behaviour.module) );
				foundModules.forEach( module => {
					this._instantiatedBehaviors.push( new behaviour.name( module ) );
				})
			 }
		)
	}

	restart() {

		this._instantiatedBehaviors.forEach( behaviour => behaviour.destroy() );
		this._looksForBehaviorsAndInitializesThem();
	}
}

export default BehaviourLauncher;