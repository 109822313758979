import Swiper from "swiper/bundle"; // eslint-disable-line

class Timeline {
	constructor() {
		Array.from(document.querySelectorAll(".js-timeline")).forEach((el) =>
			this.init(el)
		);
	}

	init(el) {
		this.timeline = new Swiper(el.querySelector(".swiper-container"), {
			freeMode: true,
			grabCursor: true,
			spaceBetween: 24,

			// Navigation arrows
			navigation: {
				nextEl: el.querySelector(".js-timeline-button-next"),
				prevEl: el.querySelector(".js-timeline-button-prev"),
			},

			// Breakpoints
			breakpoints: {
				0: {
					slidesPerView: "auto",
					slidesPerGroup: 1,
					centeredSlides: true,
				},
				991: {
					slidesPerView: 4,
					slidesPerGroup: 1,
					centeredSlides: true,
				},
			},
		});
	}
	/* eslint-enable */
}

export default Timeline;
