class Locations {
	constructor() {

		this._moduleContainer = document.querySelector( ".locations" );
		if( this._moduleContainer ) this.init();
	}

	init() {
		
		let resizeTimer;

		const moduleContainer = this._moduleContainer;
		const locationsMap = moduleContainer.querySelector( ".locations__map" );
		const locationItemsList = Array.from( moduleContainer.querySelectorAll( ".locations__item" ) ); 
		const mapMarkerList = Array.from( moduleContainer.querySelectorAll( ".locations__map__marker" ) ); 
		const select = moduleContainer.querySelector( ".locations__select" );

		let currentLocation = "";

		select.addEventListener( "change", selectChangeHandler );
		mapMarkerList.forEach( mapMarker => mapMarker.addEventListener( "click", mapMarkerClickHandler ) );

		initLocationItems(); 
		updateLocations( currentLocation );
		initResizeListener();

		function initLocationItems() {
			
			locationItemsList.forEach( locItem => {
				locItem.classList.add( "u-visually-hidden" );
				locItem.style.position = "absolute";
				locationsMap.insertBefore( locItem , null );
			})
		}

		function initResizeListener() {

			window.addEventListener('resize', e => {

				clearTimeout(resizeTimer);
				if( document.querySelector( ".locations" ) ) resizeTimer = setTimeout( updateLocationLeft, 250);  
			});
		}

		function updateLocationLeft() {

			if ( currentLocation ) {
				showCurrentLocation( currentLocation );
			}
		}

		function selectChangeHandler( e ) {

			const lastSelectedLocation = select.options[ select.selectedIndex ].value;
			updateLocations( lastSelectedLocation );
		}

		function mapMarkerClickHandler( e ) {

			const lastSelectedLocation = e.currentTarget.dataset.target;
			updateLocations( lastSelectedLocation );
		}

		function closeButtonClickHandler( e ) {

			select.selectedIndex = 0;
			hideLastLocation();
			currentLocation = "";
		}

		function hideLastLocation() {

			if ( currentLocation !== "" ) {
			
				const currentLocationNode = document.getElementById( currentLocation );
				currentLocationNode.classList.add("u-visually-hidden");

				const closeButton = currentLocationNode.querySelector(".locations__item__close");
				closeButton.removeEventListener("click", closeButtonClickHandler );

				const currentMarker = locationsMap.querySelector( `[data-target="${currentLocation}"` )
				currentMarker.classList.remove("current-item");
				currentMarker.style.pointerEvents=null;
			}
		}

		function showCurrentLocation( lastSelectedLocation ) {

			const lastLocationNode = document.getElementById( lastSelectedLocation );
			lastLocationNode.classList.remove("u-visually-hidden");

			const closeButton = lastLocationNode.querySelector(".locations__item__close");
			closeButton.addEventListener("click", closeButtonClickHandler );
			
			const verticalOffset = 4;

			const currentMarker = locationsMap.querySelector( `[data-target="${lastSelectedLocation}"` );
			const currentMarkerLeft = parseInt( currentMarker.style.left );
			const currentMarkerTop = parseInt( currentMarker.style.top );
			currentMarker.classList.add("current-item");
			currentMarker.style.pointerEvents="none";

			lastLocationNode.style.left = ( currentMarkerLeft ) + "%";
			lastLocationNode.style.top = ( currentMarkerTop + verticalOffset ) + "%";


			const xlocationNodeInPx = document.documentElement.offsetWidth*currentMarkerLeft/100;
			const rightLocationNodeInPx = xlocationNodeInPx + lastLocationNode.offsetWidth;
			if( rightLocationNodeInPx >= document.documentElement.offsetWidth ) {
				const widthMapContainer = locationsMap.offsetWidth;
				const widthLocation = lastLocationNode.offsetWidth;
				const locationPercentageWidth = (widthLocation / widthMapContainer) * 100;
				const leftLocation = currentMarkerLeft -  locationPercentageWidth;
				lastLocationNode.style.left = leftLocation + "%";
			}
		}

		function updateSelect( lastSelectedLocation ) {

			const selectOptionsList  = Array.from(select.options);
			const idSelectedMapLocation = selectOptionsList.findIndex( selectedOption => selectedOption.value === lastSelectedLocation );
			select.selectedIndex = idSelectedMapLocation;
		}

		function updateLocations( lastSelectedLocation ) {

			if( lastSelectedLocation === "" ) {
	
				select.selectedIndex = 0;
			}
			else {
				showCurrentLocation( lastSelectedLocation );
				updateSelect( lastSelectedLocation );
			}

			hideLastLocation();

			currentLocation = lastSelectedLocation;
		}
	}
}

export default Locations;
